<template>
  <div class="smssetting">
    <Tabs type="card" :animated="false" v-model="TabPaneType">
      <TabPane label="阿里云OSS存储" name="alioss"></TabPane>
      <TabPane label="七牛云对象存储" name="qiniu"></TabPane>
      <TabPane label="腾讯云COS存储" name="txcos"></TabPane>
      <TabPane label="本地存储" name="local"></TabPane>
    </Tabs>
    <div class="set-m">
      <div class="settw">
        <div class="local-tips" v-if="TabPaneType === 'local'">
          <div>文件将存储在本地服务器，默认保存在 public/upload 目录，文件以 HASH 命名。</div>
          <div>文件存储的目录需要有读写权限，有足够的存储空间。特别注意，本地存储暂不支持图片压缩！</div>
        </div>
        <Form :label-width="90">
          <FormItem label="链接类型LinkType" class="ivu-form-item-required">
            <RadioGroup v-model="data.link_type">
              <Radio v-for="(item,key) in other_data.link_type" :key="key" :label="key" :disabled="!editable">{{item}}</Radio>
            </RadioGroup>
            <Tooltip max-width="300" transfer content="类型为“简洁链接”时链接将只返回 hash 地址，而“完整链接”将携带参数保留文件名，图片压缩功能云平台会单独收费。">
              <Icon size="22" type="ios-information-circle" />
            </Tooltip>
          </FormItem>
          <FormItem label="允许类型AllowExts" class="ivu-form-item-required">
            <Input v-model="data.allow_exts" maxlength="300" :disabled="!editable"></Input>
          </FormItem>
          <div v-if="TabPaneType === 'alioss'">
            <FormItem label="访问协议Protocol" class="ivu-form-item-required">
              <RadioGroup v-model="data.alioss_http_protocol">
                <Radio v-for="(item,key) in other_data.http_protocol" :key="key" :label="key" :disabled="!editable">{{item}}</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem label="存储区域Region" class="ivu-form-item-required">
              <Select v-model="data.alioss_point" style="width:505px" :disabled="!editable">
                <Option v-for="(item,key) in other_data.aliossPoints" :value="key" :key="key">{{item}}</Option>
              </Select>
            </FormItem>
            <FormItem label="空间名称Bucket" class="ivu-form-item-required">
              <Input v-model="data.alioss_bucket" maxlength="300" :disabled="!editable"></Input>
            </FormItem>
            <FormItem label="访问域名Domain" class="ivu-form-item-required">
              <Input v-model="data.alioss_http_domain" maxlength="300" :disabled="!editable"></Input>
            </FormItem>
            <FormItem label="访问密钥AccessKey" class="ivu-form-item-required">
              <Input v-model="data.alioss_access_key" maxlength="300" :disabled="!editable"></Input>
            </FormItem>
            <FormItem label="安全秘钥SecretKey" class="ivu-form-item-required">
              <Input v-model="data.alioss_secret_key" maxlength="300" :disabled="!editable"></Input>
            </FormItem>
          </div>
          <div v-else-if="TabPaneType === 'qiniu'">
            <FormItem label="访问协议Protocol" class="ivu-form-item-required">
              <RadioGroup v-model="data.qiniu_http_protocol">
                <Radio v-for="(item,key) in other_data.http_protocol" :key="key" :label="key" :disabled="!editable">{{item}}</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem label="存储区域Region" class="ivu-form-item-required">
              <RadioGroup v-model="data.qiniu_region">
                <Radio v-for="(item,key) in other_data.qiniuPoints" :key="key" :label="item" :disabled="!editable">{{item}}</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem label="空间名称Bucket" class="ivu-form-item-required">
              <Input v-model="data.qiniu_bucket" maxlength="300" :disabled="!editable"></Input>
            </FormItem>
            <FormItem label="访问域名Domain" class="ivu-form-item-required">
              <Input v-model="data.qiniu_http_domain" maxlength="300" :disabled="!editable"></Input>
            </FormItem>
            <FormItem label="访问密钥AccessKey" class="ivu-form-item-required">
              <Input v-model="data.qiniu_access_key" maxlength="300" :disabled="!editable"></Input>
            </FormItem>
            <FormItem label="安全秘钥SecretKey" class="ivu-form-item-required">
              <Input v-model="data.qiniu_secret_key" maxlength="300" :disabled="!editable"></Input>
            </FormItem>
          </div>
          <div v-else-if="TabPaneType === 'txcos'">
            <FormItem label="访问协议Protocol" class="ivu-form-item-required">
              <RadioGroup v-model="data.txcos_http_protocol">
                <Radio v-for="(item,key) in other_data.http_protocol" :key="key" :label="key" :disabled="!editable">{{item}}</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem label="存储区域Region" class="ivu-form-item-required">
              <Select v-model="data.txcos_point" style="width:505px" :disabled="!editable">
                <Option v-for="(item,key) in other_data.txcosPoints" :value="key" :key="key">{{item}}</Option>
              </Select>
            </FormItem>
            <FormItem label="空间名称Bucket" class="ivu-form-item-required">
              <Input v-model="data.txcos_bucket" maxlength="300" :disabled="!editable"></Input>
            </FormItem>
            <FormItem label="访问域名Domain" class="ivu-form-item-required">
              <Input v-model="data.txcos_http_domain" maxlength="300" :disabled="!editable"></Input>
            </FormItem>
            <FormItem label="访问密钥AccessKey" class="ivu-form-item-required">
              <Input v-model="data.txcos_access_key" maxlength="300" :disabled="!editable"></Input>
            </FormItem>
            <FormItem label="安全秘钥SecretKey" class="ivu-form-item-required">
              <Input v-model="data.txcos_secret_key" maxlength="300" :disabled="!editable"></Input>
            </FormItem>
          </div>
          <div v-else>
            <FormItem label="访问协议Protocol" class="ivu-form-item-required">
              <RadioGroup v-model="data.local_http_protocol">
                <Radio v-for="(item,key) in other_data.httpLocalProtocol" :key="key" :label="key" :disabled="!editable">{{item}}</Radio>
              </RadioGroup>
              <Tooltip max-width="300" transfer content="本地存储访问协议，其中 HTTPS 需要配置证书才能使用（ FOLLOW 跟随系统，PATH 文件路径，AUTO 相对协议 ）">
                <Icon size="22" type="ios-information-circle" />
              </Tooltip>
            </FormItem>
            <FormItem label="访问域名Domain" class="ivu-form-item-required">
              <Input v-model="data.local_http_domain" maxlength="300" :disabled="!editable" style="width: 460px"></Input>
              <Tooltip max-width="300" placement="left" transfer content="填写上传后的访问域名（不指定时根据当前访问地址自动计算），如：a.tianyue.cc">
                <Icon size="22" type="ios-information-circle" />
              </Tooltip>
            </FormItem>
          </div>
          <FormItem label="使用状态">
            <i-switch :true-value="TabPaneType" false-value="" :value="data.type!=TabPaneType?'':data.type" :disabled="!editable" @on-change="change_driver($event)">
              <span slot="open">开</span>
              <span slot="close">关</span>
            </i-switch>
            <div class="tips br">同时只能开启一种且必须开启一种，关闭默认切换本地存储！</div>
          </FormItem>
          <FormItem label="">
            <Button type="info" style="width: 100px;" @click="SaveOrEdit">{{editable?'保存':'修改'}}</Button>
            <Button type="default" style="width: 100px; margin-left: 20px;" v-if="editable" @click="calcel">取消</Button>
          </FormItem>
        </Form>
      </div>
    </div>
    <Spin size="large" fix v-if="page_loading"></Spin>
  </div>
</template>
<script>
export default {
  name: 'OssSetting',
  data(){
    return {
      TabPaneType:'local',
      data:{},
      other_data:{
        'link_type':{},
        'http_protocol':{},
        'aliossPoints':{},
        'qiniuPoints':{},
        'txcosPoints':{},
        'httpLocalProtocol':{}
      },
      yvalue:{},
      editable:false,
      page_loading:false,
    }
  },
  created() {
    this.init();
  },
  methods:{
    init:function (){
      var _this=this;
      this.page_loading=true;
      this.requestApi('/adm/osssetting.html').then(function (res){
        _this.page_loading=false;
        if(res.status==1){
          _this.data=_this.$set(_this,'data',res.data);
         if(res.data.type) _this.TabPaneType = res.data.type;
          _this.other_data=_this.$set(_this,'other_data',res.other_data);
          _this.yvalue=JSON.stringify(res.data);
        }
      })
    },
    /**
     * 保存选项
     */
    SaveOrEdit:function (){
      if(this.editable){//保存
        var _this=this;
        this.page_loading=true
        this.requestApi('/adm/save_osssetting.html',this.data).then(function (res){
          _this.page_loading=false;
          if(res.status==1){
            _this.editable=false;
            _this.tipSuccess(res.msg);
            _this.init();
          }else{
            _this.alertError(res.msg);
          }
        })
      }else{//修改
        this.editable=true;
      }
    },
    /**
     * 开关切换
     */
    change_driver:function (e){
      this.data.type = e;
      setTimeout(()=>{
        if(this.data.type == '') this.data.type = 'local';
      },200)
    },
    /**
     * 取消
     */
    calcel:function (){
      this.editable=false;
      this.data=JSON.parse(this.yvalue);
    }
  }
}
</script>
<style lang="less">
@import "~@/assets/css/sysset/syssetting";
</style>